import React from "react";
import styled from "styled-components";
import { HTMLContent } from "../../../components/Content";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

export const SlideshowTitle = styled.h1`
  ${({ theme }) => theme.common.typography.h1};
  color: ${({ theme }) => theme.common.palette.red};
`;

export const SlideshowContent = styled.p`
white-space: normal;
  max-width:80vw !important;
`;

const SlideshowContentTemplate = ({ title, content }) => {

  const processedContent = React.useMemo(() => {
    return remark()
          .use(recommended)
          .use(remarkHtml)
          .processSync(content)
          .toString()
  }, [content]);

  return (
    <>
      <SlideshowTitle>{title}</SlideshowTitle>
      <HTMLContent
        content={processedContent}
      />
    </>
  );
};


export default SlideshowContentTemplate;
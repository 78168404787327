import React from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const StyledCarousel = styled(Carousel)`
  .slide {
    background: transparent;
    padding: 50px 40px;
  }
  .control-arrow.control-prev {
    &:before {
      border-right: 8px solid black !important;
    }
  }

  .control-arrow.control-next {
    &:before {
      border-left: 8px solid black !important;
    }
  }
  .dot {
    background-color: black !important;
  }
`;

export default ({ items }) => {
  //console.log(items);
  return (
    <StyledCarousel emulateTouch={false} swipeable={false} showStatus={false}>
      {items.map(({ render }, index) => {
        return <div index={index}>{render}</div>;
      })}
    </StyledCarousel>
  );
};

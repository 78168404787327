import React from "react";
import Signature from "./SlideshowSignature/SlideshowSignature.js";
import Footer from "./SlideshowFooter/SlideshowFooter.js";
import SlideshowContent from "./SlideshowContent/SlideshowContent.js";
import SlideshowImage from "./SlideshowImage/SlideshowImage.js";
import styled from "styled-components";

const SlideshowContainer = styled.div`
  display: flex;
  align-items:center;
  .slideshowContent {
    width:calc(80vw - 40px);
    padding:0px 20px;
    min-width: 200px !important;
  }
  .slideshowImage{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    flex-direction:column;
  }
`;

const Slideshow = ({ title, content, image, text }) => {
  return (
    <SlideshowContainer>
      <div className="slideshowImage">
        <SlideshowImage image={image} />
        {text}
      </div>
      <div className="slideshowContent">
        <SlideshowContent
          className="slideshowContentDiv"
          title={title || "I am a title"}
          content={content || "Im some content"}
        />
        <Signature />
      </div>
    </SlideshowContainer>
  );
};

export default Slideshow;

import styled from "styled-components";

export const SlideshowFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.common.palette.brown};
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 20px;
  white-space: nowrap;
  * {
    min-width: 20px;
  }
  &:first-child {
    margin-right: 10px;
  }
  &:last-child {
    margin-left: 10px;
  }
`;

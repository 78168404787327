import React from "react";
import styled from "styled-components";
import PreviewCompatibleImage from "../../PreviewCompatibleImage";

export const SlideshowImageContainer = styled(PreviewCompatibleImage)`
  width: 40vw;
  height: 40vw;
  min-width: 300px !important;
  min-height: 300px !important;
  border: 2px solid ${({ theme }) => theme.common.palette.brown};
  display: flex;
  justify-content:center;
`;

const SlideshowImage = ({ image }) => (
  <SlideshowImageContainer imageInfo={image} tag="div" />
);

export default SlideshowImage;
import styled from "styled-components";

export const SlideshowSignatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content:center;
  align-items:center;
  position: relative;
  .signatureImage {
    width: 150px !important;
    height: 100px !important;
    background-size: "contain" !important;
  }
  p {
    margin: 0px 60px;
    font-weight: bold;
    white-space: nowrap;
  }
  .name {
    color: ${({ theme }) => theme.common.palette.red};
    font-style: italic;
  }
  .site {
    color: ${({ theme }) => theme.common.palette.brown};
  }
`;

export const SignatureContainer = styled.div`

`;

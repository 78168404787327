import React from "react";
import { graphql, StaticQuery } from "gatsby";
import {
  SlideshowSignatureContainer,
  SignatureContainer,
} from "./SlideshowSignature.styled";
import PreviewCompatibleImage from "../../PreviewCompatibleImage";
const SignatureTemplate = ({ signatureName, signatureURL, slideshowSig }) => {
  return (
    <SlideshowSignatureContainer>
      <SignatureContainer>
        <PreviewCompatibleImage
          className="signatureImage"
          imageInfo={slideshowSig}
          tag="section"
        />
      </SignatureContainer>
      <p className="name">{signatureName}</p>
    </SlideshowSignatureContainer>
  );
};

const SignatureComponent = () => (
  <StaticQuery
    query={graphql`
      query SlideshowSignatureQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "slideshow" } }) {
          frontmatter {
            signatureName
            signatureURL
            slideshowSig {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <SignatureTemplate
          signatureName={data.markdownRemark.frontmatter.signatureName}
          signatureURL={data.markdownRemark.frontmatter.signatureURL}
          slideshowSig={data.markdownRemark.frontmatter.slideshowSig}
        />
      );
    }}
  />
);

export default SignatureComponent;

import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Carousel from "../components/Carousel/Carousel";
import styled from "styled-components";
import Slideshow from "../components/Slideshows/Slideshow";

const OuterPadding = styled.div`
  margin-bottom: 80px;
  height: auto;
  * {
    font-family: "Noto Serif JP", sans-serif, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "EB Garamond", serif, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif !important;
  }
`;

export const WysiwygTemplate = ({ html, frontmatter, iconFeatures }) => {
  const builtCarouselItems = React.useMemo(() => {
    return iconFeatures.map((iconFeature) => {
      return {
        render: <Slideshow {...iconFeature} />,
      };
    });
  }, [iconFeatures]);
  return (
    <Layout>
      <Carousel items={builtCarouselItems} />
    </Layout>
  );
};

export const WYSIWYG = (data) => {
  const {
    data: {
      markdownRemark: { html, frontmatter },
      slideshowContent: {
        frontmatter: { iconFeatures },
      },
    },
  } = data;
  return (
    <WysiwygTemplate
      iconFeatures={iconFeatures}
      html={html}
      frontmatter={frontmatter}
    />
  );
};

export const pageQuery = graphql`
  query WYSIWYGCarousel($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        carouselitems {
          innercontent
        }
        initialcontent
      }
    }
    slideshowContent: markdownRemark(
      frontmatter: { templateKey: { eq: "slideshow" } }
    ) {
      frontmatter {
        iconFeatures {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          text
          title
          content
        }
      }
    }
  }
`;

export default WYSIWYG;
